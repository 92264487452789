import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any, field: string, args?: boolean): any[] {
    args = !args;
    let direction = args ? 1 : -1;
    let innerObj = field.split('.');
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (innerObj.length > 1) {
        if (a[innerObj[0]][innerObj[1]] === null) {
          a[innerObj[0]][innerObj[1]] = '';
        }
        if (b[innerObj[0]][innerObj[1]] === null) {
          b[innerObj[0]][innerObj[1]] = '';
        }
        let aa = (typeof a[innerObj[0]][innerObj[1]] === 'number') ? a[innerObj[0]][innerObj[1]] : a[innerObj[0]][innerObj[1]].toString().toLowerCase();
        let bb = (typeof b[innerObj[0]][innerObj[1]] === 'number') ? b[innerObj[0]][innerObj[1]] : b[innerObj[0]][innerObj[1]].toString().toLowerCase();
        if (aa < bb) {
          return -1 * direction;
        } else if (aa > bb) {
          return direction;
        } else {
          return 0;
        }
      } else {
        if (a[field] !== undefined) {
          if (a[field] === null) {
            a[field] = '';
          }
          if (b[field] === null) {
            b[field] = '';
          }
          let aa = (typeof a[field] === 'number') ? a[field] : a[field].toString().toLowerCase();
          let bb = (typeof b[field] === 'number') ? b[field] : b[field].toString().toLowerCase();
          if (aa < bb) {
            return -1 * direction;
          } else if (aa > bb) {
            return direction;
          } else {
            return 0;
          }
        }
      }
    });
    return array;
  }

}
