import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from "./_GUARDS";
import { LoginComponent } from "./auth/login/login.component";
import { ErrorPageComponent } from "./error-page/error-page.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dispensers',
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dispensers',
    loadChildren: () => import('./dispensers/dispensers.module').then(m => m.DispensersModule)
  },
  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(
    routes,
    {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  declarations: []
})
export class AppRoutingModule {}
