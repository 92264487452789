export class User {
  /*id: number;*/
  api_key: string;
  name: string;
  email: string;
  permissions: string;
  role: string;

  constructor(response: User) {
    /*this.id = response.id;*/
    this.api_key = response.api_key;
    this.name = response.name;
    this.email = response.email;
    this.permissions = response.permissions;
    this.role = response.role;
  }

}


/*
api_key: "JDJ5JDEwJGV6QnZEYmcuWVRmWFg2cklobkNWSk9iVEk3MG5BRkdMenhyTmRYVWhwNExEOGpNWG52N0lT"
email: "ykukh@5prosoftware.com"
name: "Yulian"
permissions: "01000000"
role: "operator"*/
