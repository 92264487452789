import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class DispensersService {
  USER: any;

  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }

  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  public getDispensersList(data): Observable<any> {
    const url = `${this.env.apiBase}dispenser`;
    this.getUserData();
    let params = new HttpParams()
      .append('page', data.page)
      .append('per_page', data.per_page)
      .append('order_by', data.order_by)
      .append('order_type', data.order_type);
    return this.http.get<Object>(url, {params});
  }
  public getSingleDispenser(data): Observable<any> {
    const url = `${this.env.apiBase}dispenser/${data.id}`;
    this.getUserData();
    return this.http.get<Object>(url);
  }
  public getSingleDispenserLogs(data): Observable<any> {
    const url = `${this.env.apiBase}dispenser/${data.id}/logs`;
    this.getUserData();
    let params = new HttpParams()
      .append('page', data.page)/*
      .append('per_page', data.per_page)
      .append('order_by', data.order_by)
      .append('order_type', data.order_type)
      .append('cartridge_change', data.cartridge_change)*/;
    return this.http.get<Object>(url, {params});
  }

  public editDispenserData(data): Observable<any> {
    const url = `${this.env.apiBase}dispenser`;
    this.getUserData();
    let params = new HttpParams()
      .append('id', data.id);
    return this.http.put<Object>(url, data, {params: params});
  }

}
