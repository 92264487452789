import { Injectable, EventEmitter } from '@angular/core';
import {Subject} from "rxjs";

@Injectable()
export class SharedService {

  changeLanguageEvent: EventEmitter<any> = new EventEmitter();

  private mobileUserData = new Subject<any>();
  private childLoad = new Subject<any>();
  passMobileUserData$ = this.mobileUserData.asObservable();
  childLoadEmit$ = this.childLoad.asObservable();

  constructor() { }

  passData(data) {
    this.mobileUserData.next(data);
  }
  emitChanges(data) {
    this.childLoad.next(data);
  }

}
