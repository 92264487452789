import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { Router } from "@angular/router";

import { EnvService } from "./env.service";
import { AlertService } from "./alert.service";
import { User } from "../_MODELS";

@Injectable()
export class AuthenticationService {

  constructor(
    private env: EnvService,
    private http: HttpClient,
    private router: Router,
    private _AlertService: AlertService
  ) {}

  login(email: string, password: string) {
    console.log(this.env);
    let params = new HttpParams()
      .append('email', email)
      .append('password', password);
    return this.http.post<any>(`${this.env.apiBase}login`, {}, {params: params}).subscribe(res => {
      const user = new User(res['user']);
      if (user && user['api_key']) {
        // login successful if there's a jwt token in the response
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this._AlertService.success('Alert.Successful_login');
        this.router.navigate(['/']);
      }
    }, err => {
      if (err['status'] >= 401 && err['status'] <= 499) {
        this._AlertService.error('Alert.Wrong_email_or_password');
      } else {
        this._AlertService.error(err['statusText']);
      }
    });
  }

  logout(): void {
    const tempLang = localStorage.getItem('lang');
    localStorage.clear();
    localStorage.setItem('lang', tempLang);
    this.router.navigate(['/login']);
  }

}
