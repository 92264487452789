import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, pipe } from "rxjs";
import { tap } from "rxjs/operators";
// import 'rxjs/add/operator/do';
import { Router } from "@angular/router";
import { AuthenticationService, AlertService } from "../_SERVICES";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private _AuthService: AuthenticationService,
    private _AlertService: AlertService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.api_key) {
      request = request.clone({
        setHeaders: {
          Authorization: currentUser.api_key
        }
      });
    }

    return next.handle(request)
      .pipe(tap((event: HttpEvent<any>) => {}, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser !== null) {
              this.router.navigate(['/login']);
              this._AuthService.logout();
              this._AlertService.error('Alert.Unauthorized_issue');
            }
          }
        }
      }))
      /*.do((event: HttpEvent<any>) => {}, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser !== null) {
              this.router.navigate(['/login']);
              this._AuthService.logout();
              this._AlertService.error('Alert.Unauthorized_issue');
            }
          }
        }
      });*/
  }
}
