import { Injectable } from '@angular/core';

@Injectable()
export class EnvService {

  public apiBase = '';
  public appToken = '';
  public env = '';
  public enableDebug = true;

  constructor() { }

}
