<header>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <ul class="nav-menu main-nav-menu">
          <li class="nav-item logo">
            <a [routerLink]="['/']">
              <img src="{{'assets/images/icSplashLogospectro.png'}}" alt="spectro-logo">
            </a>
          </li>
          <!--<li class="nav-item" [routerLinkActive]="'active'" *ngIf="USER">
            <a [routerLink]="['/dispensers']">{{'Header.Dispensers' | translate}}</a>
          </li>-->
        </ul>
        <ul class="nav-menu">
          <li class="nav-item logout" *ngIf="USER">
            <a class="logout_btn" (click)="logout()">{{'Header.Logout' | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
