import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../_SERVICES";
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private _AuthenticationService: AuthenticationService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.formInit();
  }

  formInit(): void {
    const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
    this.loginForm = this.fb.group({
      email: [ '', [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)] ],
      password: ['', [Validators.minLength(6), Validators.required]]
    });
  }

  login(form) {
    this._AuthenticationService.login(
      form['controls']['email']['value'],
      form['controls']['password']['value']
    );
  }

}
